import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18n';

import App from './App';
// import makeServer from './server';

// if (process.env.NODE_ENV === 'development') {
//   makeServer({ environment: 'development' });
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
