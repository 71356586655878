import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { setup } from 'goober';
import { createGlobalStyles } from 'goober/global';
import { shouldForwardProp } from 'goober/should-forward-prop';
import { SWRConfig } from 'swr';

import './assets/fonts/PFEncoreSansPro-Bold.woff2';
import './assets/fonts/PFEncoreSansPro-Light.woff2';
import './assets/fonts/PFEncoreSansPro-Medium.woff2';
import './assets/fonts/PFEncoreSansPro-Regular.woff2';

import LandscapeMode from './components/LandscapeMode';
import Router from './routing/router';

setup(
  React.createElement,
  undefined,
  undefined,
  // Transient props
  shouldForwardProp((prop) => prop[0] !== '$')
);

// eslint-disable-next-line react/prop-types
const Mobile = ({ children }) => {
  // @TODO talk with maro tell us the max-width
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  return isMobile && isPortrait ? children : null;
};
// eslint-disable-next-line react/prop-types
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  return isNotMobile || isLandscape ? children : null;
};

const GlobalStyles = createGlobalStyles`
@font-face {
  font-family:  "PF Encore Sans Pro";
  src: local('PF Encore Sans Pro'), url(./assets/fonts/PFEncoreSansPro-Regular.woff2) format('woff2')
}

@font-face {
    font-family: 'PF Encore Sans Pro';
    font-weight: 600;
    src: local('PF Encore Sans Pro'), url(./assets/fonts/PFEncoreSansPro-Medium.woff2) format('woff2')
}

@font-face {
    font-family: 'PF Encore Sans Pro';
    font-weight: bold;
    src: local('PF Encore Sans Pro'), url(./assets/fonts/PFEncoreSansPro-Bold.woff2) format('woff2')
}
@font-face {
  font-family: 'PF Encore Sans Pro';
  font-weight: light;
  src: local('PF Encore Sans Pro), url(./assets/fonts/PFEncoreSansPro-Light.woff2) format('woff2')
}

  html,
  body {
    height: 100%;
    font-family: 'PF Encore Sans Pro', sans-serif;
    margin: 0!important;
  }

  * {
    box-sizing: border-box;
  }
 
`;

const App = () => {
  const { i18n } = useTranslation();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || '',
    timeout: 2000,
  });

  const fetchWithToken = (url) => {
    instance.defaults.headers.common['Accept-Language'] = i18n.language;
    return instance.get(url).then((res) => res);
  };
  return (
    <SWRConfig
      value={{
        fetcher: (url) => fetchWithToken(url),
        suspense: true,
      }}
    >
      <div>
        <GlobalStyles />
        <Mobile>
          <Router />
        </Mobile>
        <Default>
          <LandscapeMode />
        </Default>
      </div>
    </SWRConfig>
  );
};

export default App;
